import BMap from "BMap";
import BMapLib from "BMapLib";
import trackIcon from '../../assets/image/track/track_point.png'
import centerIcon from '../../assets/image/map/icon-center.png';
import emptyIcon from '../../assets/image/map/icon-empty.png';
import closeIcon from '../../assets/image/map/icon-closeDis.png';
import positionMarker from '../../assets/location.png'
import GPS from '@/utils/coordinate'
function BaiduMap() {
    this.map = null; //地图
    this.openInfo = null; //当前信息窗
    this.timer = null;//定时器（轨迹播放与暂停时使用）
    this.playMode = 0; //判断是否是打点过后的轨迹播放
    this.trackPointIndex = 0; //第几个轨迹点
    this.markers = []; //所有的打点 用于存储打点播放时产生的大量打点(地图marker对象)
    this.polyline = null; //轨迹线
    this.allPoints = []; //所有点
    this.allTrackData = []; //存储所有轨迹点
    this.trackDomInfo = {};
    this.tempPoint = [];//原始轨迹点（包含对应点的各种信息）
    this.trackPlaySpeed = 1;//轨迹播放速度
    this.trackCallBack = null;//轨迹播放回调函数
    this.circleObj = null;//圆形围栏配置
    this.labelObj = null;//围栏文字配置
    this.polygon = null;//多边形围栏
    this.drawingManager = null;//存储绘制
    this.drawTip = false;//鼠标移动施法显示绘制提示
    this.bubbleData = {};//当前气泡数据
    this.myDis = '';//测距
    this.tempFlag = false;//判断是否显示覆盖物
    this.currentStatus = false;//当前全屏状态
}
/** 初始化百度地图
 * @param {String} id 地图id
 * @param {Object} point 初始中心点
 * @param {Number} zoom 地图初始级别
 * @param {Object} opt 地图配置
 */
BaiduMap.prototype.initMap = function (id, point, zoom, opt) {
    this.map = new BMap.Map(id, {
        enableMapClick: false,
    });
    var tempPoint = GPS.GPSToBaidu(point.lat, point.lng);
    let centerPoint = new BMap.Point(
        tempPoint.lng,
        tempPoint.lat
    ); //中心点
    let scrollZoom = opt && opt.scrollZoom ? opt.scrollZoom : true;
    if (opt && opt.zoomControl) {
        let opts = {anchor: BMAP_ANCHOR_BOTTOM_RIGHT, type:  BMAP_NAVIGATION_CONTROL_ZOOM};    
        this.map.addControl(new BMap.NavigationControl(opts));
    }
    this.map.centerAndZoom(centerPoint, zoom); //设置地图初始中心点和初始级别
    this.map.enableScrollWheelZoom(scrollZoom); //是否开启滚轮缩放 
};
/** 创建点
 * @param {String} lnglat 点的经纬度
 */
BaiduMap.prototype.createPoint = function (lnglat) {
   var tempPoint = GPS.GPSToBaidu(lnglat.lat, lnglat.lng)
    return new BMap.Point(tempPoint.lng, tempPoint.lat); 
};
/**设置中心点和层级 */
BaiduMap.prototype.setCenterAndZoom = function(obj, zoom) {
    var point = this.createPoint(obj);
    this.map.centerAndZoom(point, zoom);
}
/** 创建图标
 * @param {Object} op 图标配置
 */
BaiduMap.prototype._createIcon = function (op) {
    let icon = {
        icon: op.url ?
            new BMap.Icon(op.url, op.size ? new BMap.Size(op.size[0], op.size[1]) : new BMap.Size(20, 20)) : "",
        imageOffset: new BMap.Size(0, 0),
        offset: new BMap.Size(0, 0),
    };
    icon.icon.setImageSize(new BMap.Size(op.size[0], op.size[1]));
    if (op.anchor) {
        icon["offset"] = new BMap.Size(op.anchor[0], -op.anchor[1]);
    }
    return icon;
};
/** 创建标注
 * @param {Object} point 点
 * @param {Object} opt 配置项
 * @param {Object} icon 图标配置
 * @param {Object} dom 信息窗内容
 * @param {Boolean} show 是否默认显示信息窗
 * @param {Boolean} noClick 判断marker是否不可点击
 * @param {Object} domData 气泡数据
 * @param {Function} callback 点击气泡之后
 * @param {Boolean} clickFlag 是否绑定事件
 * @param { Boolean } changePointFlag 是否转化坐标
 */
BaiduMap.prototype.createMarker = function (point, opt, dom, show, noClick, domData, callback,clickFlag,changePointFlag) {
    let iconData, marker;
    var tempPoint = null;
    if(!changePointFlag) {
        tempPoint = this.createPoint({lng:point.lng, lat: point.lat});
    } else {
        tempPoint = {lng:point.lng, lat: point.lat}
    }
    
    if (opt) {
        iconData = this._createIcon({
            url: opt.icon && opt.icon.url ? opt.icon.url : '',
            size: opt.icon ? opt.icon.size : [],
            anchor: opt.icon ? opt.icon.anchor : [],
        });
        marker = new BMap.Marker(tempPoint, opt.icon ? iconData : {});
    } else {
        marker = new BMap.Marker(tempPoint, {});
    } 
    this.map.addOverlay(marker);
    let that = this;
    // let tempData = point.content ? point.content : {};//信息擦混中的数据
    if (show) {
        this.showInfoWindow(marker, point, dom);
    }
    if(!clickFlag) {
        marker.addEventListener("click", function () {
            if (that.openInfo) {
                that.openInfo.close();
                that.closeAllInfoBox();
            }
            that.createInfoWindow(marker, point, {
                content: dom,
                options: {
                    offset: new BMap.Size(0, 22),
                },
            }); 
            if (callback) {
                callback(domData);
            }
        });
    }
    return marker;
};
/** 
 * 默认显示气泡
 *  @param {Object} marker 标注点
 * @param {Object} tempData 气泡数据
 * @param {Object} dom 气泡内容
 */
BaiduMap.prototype.showInfoWindow = function(marker, tempData, dom) {
    if (this.openInfo) {
        this.openInfo.close();
        this.closeAllInfoBox();
    }
    this.createInfoWindow(marker, tempData, {
        content: dom,
        options: {
            // closeIconUrl: dom ? '':  opt && opt.icon.url,
            // closeIconUrl: '../../assets/image/map/icon-close.png',
            offset: new BMap.Size(0, 22),
        },
    }); 
}
/** 创建信息窗
 * @param {Object} op 信息窗配置
 */
BaiduMap.prototype.createInfoWindow = function (marker, obj, op) {
    let html; 
    if (!obj) {
        html = `<div ref="infoBubble" class="info-bubble bubble-sm js-info-box" id="infoBox">
        <div class="bubble-header">
            <b>测试</b>
            <span class="bubble-close js-close" id="close" title="关闭">×</span>
        </div>
        <div class="bubble-content">
            <table class="table-fixed">
                <tbody>
                    <tr>
                        <td>111</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>`;
    } else {
        html = '<div class="info-container">';
        for (let i in obj) {
            if (i == 'title') {
                html += '<div class="header">' + obj[i] + '</div><div class="content"><table class="table-fixed"> <tbody>';
            } else {
                html += '<tr><td>' + obj[i] + '</td></tr>';
            }
        }
        html += '</tbody></table></div>';
    }
    if (op.content) {
        if (op.content.lng) {
            this.openInfo = new BMapLib.InfoBox(this.map, html, op.options);
        } else {
            op.content.style.display = 'block';
            this.openInfo = new BMapLib.InfoBox(this.map, op.content, op.options);
        }


    } else {
        this.openInfo = new BMapLib.InfoBox(this.map, html, op.options);
    }
    this.openInfo.open(marker);
    this.map.addOverlay(this.openInfo);
    this.closeInfoBox();
};
/** 
 * 关闭信息窗
 */
BaiduMap.prototype.closeAllInfoBox = function () {
    let dom = document.getElementsByClassName('js-info-box');
    for (let i = 0; i < dom.length; i++) {
        dom[i].style.display = 'none';
    }
}
/** 百度地图自动关闭气泡 */
BaiduMap.prototype.closeInfoBox = function () {
    let dom = document.getElementsByClassName('js-close');
    for (let i = 0; i < dom.length; i++) {
        dom[i].addEventListener('click', function () {
            dom[i].parentNode.parentNode.style.display = 'none';
        })
    }
    // this.openInfo.close();
}
/** 百度地图点击关闭气泡 */
BaiduMap.prototype.clickClose = function() {
    let dom = document.getElementsByClassName('js-close');
    for (let i = 0; i < dom.length; i++) {
        dom[i].addEventListener('click', function () {
            this.parentNode.parentNode.style.display = 'none';
        })
    }
}
/** 
 * 获取所有轨迹点并画线
 * @param {Array} allData 所有轨迹点
 * @param {Object} lineObj 轨迹线的配置
 */
BaiduMap.prototype.getAllPoints = function (allData, lineObj, dom) {
    if (this.openInfo) {
        this.openInfo.close();
        this.closeAllInfoBox();
    }

    this.map.clearOverlays();
    this.trackDomInfo = dom;
    clearInterval(this.timer);
    this.allTrackData = [];
    this.allPoints = [];

    this.tempPoint = allData;
    for (let i = 0; i < this.tempPoint.length; i++) {
        let point = this.createPoint({lat:this.tempPoint[i].lat,lng: this.tempPoint[i].lng});
        this.allPoints.push(point);
    }
    this.setViewport(this.allPoints);
    //创建第一个点
    this.deviceMarker = this.createMarker(this.tempPoint[0],{icon:{url:trackIcon,size:[16,16],anchor:[0,0]}},this.trackDomInfo,true);
    this.map.setCenter(this.allPoints[0]);
}
/** 画轨迹线
 *  @param {Array} pointArr 轨迹点集合
*  @param {Object} ops 配置项
 */
BaiduMap.prototype.createPolyline = function (pointArr, ops) {
    return new BMap.Polyline(pointArr, {
        strokeColor: ops.color,
        strokeWeight: ops.width,
        strokeOpacity: ops.opacity,
    });
};
/**
 * 设置轨迹播放的线
 */
BaiduMap.prototype._setTrackLine = function () {
    this.allTrackData = this.allPoints.slice(0,this.trackPointIndex+1);
    // this.map.setViewport(this.allTrackData);
    //优化速度太快播放抖动问题
    // if(this.trackPlaySpeed >= 8){
    //     this.setViewport(this.allPoints);
    // } else {
    //     this.setViewport(this.allTrackData);
    // }
    if (this.trackPointIndex > 0) {
        if (this.polyline) {
            this.setPolyline(this.polyline, this.allTrackData);
            this.map.addOverlay(this.polyline);
        } else {
            this.polyline = this.createPolyline(this.allTrackData, {
                color: "#3270FF",
                width: 5,
                opacity:1
            });
            this.map.addOverlay(this.polyline);
        }
    }
};
/** 轨迹坐标点
 * @param {string} type 判断是打点播放还是轨迹播放
 */
BaiduMap.prototype._trackLine = function (type,getSpeedAndPrograss) {
/*     if (this.trackPointIndex >= this.allPoints.length) {
        clearInterval(this.timer);
        return;
    } */
    if (type == 'line') {
        this._setTrackLine();
    } else {
        if (this.trackPointIndex > 1) {
            this.setMarker(this.trackPointIndex - 1);
        }
    }
    let bound = this.map.getBounds();
    if (!bound.containsPoint(this.allPoints[this.trackPointIndex])) {
        this.map.setCenter(this.allPoints[this.trackPointIndex]);
    }

    this.deviceMarker.setPosition(this.allPoints[this.trackPointIndex]); // 改变设备图标位置
    if (this.openInfo) {
        this.closeAllInfoBox();
    }
    this.map.centerAndZoom(this.allPoints[this.trackPointIndex]);
    this.createInfoWindow(this.deviceMarker, this.allPoints[this.trackPointIndex], {
        content: this.trackDomInfo,
        options: {
            closeIconUrl: '',
            offset: new BMap.Size(0, 22),
        },
    });
    this.trackPointIndex++;
    this.trackCallBack(this.trackPointIndex);
    if (this.trackPointIndex >= this.allPoints.length) {
        clearInterval(this.timer);
        this.timer = null;
        this.trackPointIndex = 0;
        this.allTrackData = []
    }
};
/**
 * 设置轨迹位置
 * @param {Object} polyline 轨迹线
 * @param {Array} points 轨迹点
 */
BaiduMap.prototype.setPolyline = function (polyline, points) {
    polyline = polyline.setPath(points);
};
/** 轨迹播放
 * 
 */
BaiduMap.prototype.play = function (getSpeedAndPrograss) {
    this.trackCallBack = getSpeedAndPrograss;//轨迹播放回调函数
    //判断进度条是否播放完
    if( this.trackPointIndex == 0) {
        if (this.openInfo) {
            this.openInfo.close();
        }
        this.map.clearOverlays();
        // debugger;
        //创建初始点
        getSpeedAndPrograss(1);
        this.deviceMarker = this.createMarker(this.tempPoint[0],{icon:{url:trackIcon,size:[16,16],anchor:[0,0]}},this.trackDomInfo,true);
    } 
    if (this.timer) {
        clearInterval(this.timer);
    }
    if (this.playMode == 1) {
        this.playMode = 0;
        this.emptyMarker();
        this.trackPointIndex--;
        this._setTrackLine();
        this.trackPointIndex++;
    }
    this.timer = setInterval(() => {
        this._trackLine('line',getSpeedAndPrograss);
    }, 1000/this.trackPlaySpeed);
};
//设置轨迹播放速度
BaiduMap.prototype.setPlaySpeed = function (speed) {
    clearInterval(this.timer);
    this.timer = null;
    let that  = this;
    this.trackPlaySpeed = speed;
    if(this.trackPointIndex > 0) {
        this.timer = setInterval(() => {
            that._trackLine('line',that.trackCallBack);
        }, 1000/speed);
    }
};
//设置进度条拖动
BaiduMap.prototype.setPrograssIndex = function (prograss) {
    if(this.timer) {
        this._emptyLine();
    }
    this.trackPointIndex = prograss;
};
/** 
 * 轨迹暂停
 */
BaiduMap.prototype.playPause = function () {
    clearInterval(this.timer);
    this.timer = null;
};
/** 
 * 轨迹打点播放
 */
BaiduMap.prototype.dotPlay = function (fn) {
    if (this.timer) {
        clearInterval(this.timer);
    }
    if (this.playMode == 0) {
        this.playMode = 1;
        this._emptyLine();
        this.trackPointIndex--;
        this.reSetPlaySchedule();
        this.trackPointIndex++;
    }
    this.timer = setInterval(() => {
        this._trackLine('dot');
        //回调传值  速度
        fn(this.tempPoint[this.trackPointIndex],);
    }, 1000/this.trackPlaySpeed);
    
    
};
/** 
 * 重设打点播放
 */
BaiduMap.prototype.reSetPlaySchedule = function () {
    this.emptyMarker();
    this.allTrackData.push(this.allPoints[this.trackPointIndex]);
    for (let i = 0; i < this.trackPointIndex; i++) {
        let point = this.allPoints[i];
        if (i < this.trackPointIndex) {
            this.setMarker(i);
        }
        if (i == this.trackPointIndex && point) {
            this.deviceMarker.setPosition(point);
        }
    }
}
/** 设置打点播放轨迹点
 * @param {Number} num 第几个点
 */
BaiduMap.prototype.setMarker = function (num) {
    let newMarker = this.createMarker(this.allPoints[num]);
    this.map.addOverlay(newMarker);
    this.markers[this.markers.length] = newMarker;
}
/** 
 * 清空轨迹线
 */
BaiduMap.prototype._emptyLine = function () {
    this.map.removeOverlay(this.polyline);
    this.polyline = null;
};
/** 批量清除标注
 * @param {Object} overlayList marker
 */
BaiduMap.prototype.emptyMarkers = function (overlayList) {
    for (var i = 0; i < overlayList.length; i++) {
        if (overlayList[i]) {
            this.map.removeOverlay(overlayList[i]);
        }
    }
};
/** 
 * 重新播放轨迹
 */
BaiduMap.prototype.replay = function () {
    this.trackPointIndex = 0;
    this.allTrackData = [];
    clearInterval(this.timer);
    this.emptyMarker();
    this._emptyLine();
    this.timer = setInterval(() => {
        this._trackLine('line');
    }, 1000/this.trackPlaySpeed);
};
/** 
 * 停止播放轨迹（重置轨迹）
 */
BaiduMap.prototype.rePause = function () {
    if (this.openInfo) {
        this.openInfo.close();
    }
    this.map.clearOverlays();
    this.deviceMarker = this.createMarker(this.tempPoint[0],{icon:{url:trackIcon,size:[16,16],anchor:[0,0]}},this.trackDomInfo,true);
    this.trackPointIndex = 0;
    clearInterval(this.timer);
    this.timer = null;
};
/** 
 * 清除marker
 */
BaiduMap.prototype.emptyMarker = function () {
    if (this.openInfo) {
        this.openInfo.close();
        if(document.getElementById('infoBox')) {
            let id = document.getElementById('infoBox');
            id.style.display = 'none';
        }
    }
    if (this.markers.length > 0) {
        this.emptyMarkers(this.markers);
        this.markers = [];
    }

}
/** 绘制围栏
 * @param {String} type 类型
 * @param {Object} circleOpt 圆形覆盖物配置项
 * @param {Object} polygonOpt 多边形覆盖物配置项
 * @param {Object} overComlpete 绘制完成后的回调函数
 */
BaiduMap.prototype.createFence = function(type, circleOpt, polygonOpt, overComlpete) {
    var that = this;
    var infoObj = {
        info: '单击开始绘制，双击结束',
        targetDiv: 'map'
    }
    var fenceType = null;
    if (type == 'circle') {
        fenceType = BMAP_DRAWING_CIRCLE;
    } else if (type == 'polygon') {
        fenceType = BMAP_DRAWING_POLYGON; 
    }
    var style = {
        fillColor: 'rgba(50, 112, 255, 0.1)',
        fillOpacity: 1,
        strokeWeight: 3,
        strokeColor: '#3270FF',
        clickable: true,
        editable: true,
        zIndex: 1,
    };
    var circleOptions = circleOpt ? circleOpt : style;
    var polygonOptions = polygonOpt ? polygonOpt : style;
    this.drawingManager = new BMapLib.DrawingManager(this.map, {
        isOpen: true,
        enableDrawingTool: false,
        enableCalculate: false,
        enableDragging: false,
        disableInertialDragging: true,
        drawingToolOptions: {
            anchor: BMAP_ANCHOR_TOP_LEFT,
            offset: new BMap.Size(5, 5),
        },
        polygonOptions: polygonOptions,
        circleOptions: circleOptions,
    });
    this.drawingManager.setDrawingMode(fenceType);
    this.drawingManager.open();
    this.drawTip = true;
    this._mouseAlt(infoObj);
    this.drawingManager.addEventListener("overlaycomplete", function(e) {
        e.overlay.enableEditing();
        overComlpete(e);
        that.drawTip = false;
        that.drawingManager.close();
    });
    
}
/** 清除指定围栏
 * @param {Object} fence
 */
BaiduMap.prototype.clearFence = function(fence) {
    this.map.removeOverlay(fence);
}
/** 停止绘制
 * 
 */
BaiduMap.prototype.closeFence = function() {
    this.drawingManager.close();
}
/** 圆形围栏
 * @param {Object} obj 圆形围栏配置
 * @param {label} label 围栏文字配置
 * @param {Function} callback
 */
BaiduMap.prototype.createCircleFence = function (obj, label, callback) {
    this.circleObj = obj;
    this.map.clearOverlays();
    this.map.setDefaultCursor("crosshair"); //设置鼠标样式
    this.map.addEventListener("click", callback);
    this.map.setDefaultCursor("url('bird.cur')");
}
/** 
 * 绘制圆形围栏
 */
BaiduMap.prototype.drawFence = function (e, mapObj) {
    let that = mapObj;
    let text = that.circleObj.name ? that.circleObj.name : "测试";
    let point = new BMap.Point(e.point.lng, e.point.lat);
    let radius = that.circleObj.radius ? that.circleObj.radius : 500;
    let circle = new BMap.Circle(point, radius, {
        fillColor: that.circleObj.fillColor ? that.circleObj.fillColor : "blue",
        strokeWeight: that.circleObj.strokeWeight ? that.circleObj.strokeWeight : 1,
        fillOpacity: that.circleObj.fillOpacity ? that.circleObj.fillOpacity : 0.3,
        strokeOpacity: that.circleObj.strokeOpacity ? that.circleObj.strokeOpacity : 0.3,
    });
    let label = new BMap.Label(text, {
        offset: new BMap.Size(0, 0),
        position: point,
    });
    label.setStyle({
        color: that.labelObj.color ? that.labelObj.color : "#fff",
        backgroundColor: that.labelObj.backgroundColor ? that.labelObj.backgroundColor :
            "transparent", //文本背景色
        borderColor: that.labelObj.borderColor ? that.labelObj.borderColor : "transparent", //文本框边框色
    });
    that.map.addOverlay(circle);
    that.map.addOverlay(label);
};
/**  多边形围栏
 * @param {Object} obj 多边形围栏配置项
 * @param {Function} callback 回调函数
 * @param {Object} mapObj 地图
 */
BaiduMap.prototype.createPolygonalFence = function (obj, callback, mapObj) {
    this.tempPolygonFlag = true;
    this.map.clearOverlays();
    this.map.setDefaultCursor("url('bird.cur')");
    if (this.tempPolygonFlag) {
        this.tempPolygonFlag = false;
        this.drawPolygons(obj, callback, mapObj);
    }
};
/** 绘制多边形
 * @param {Object} obj 多边形围栏配置项
 * @param {Function} callback 回调函数
 * @param {Object} mapObj 地图
 */
BaiduMap.prototype.drawPolygons = function (obj, callback, mapObj) {
    let styleOPtions = obj || {};
    mapObj.polygon = new BMapLib.DrawingManager(mapObj.map, {
        isOpen: true,
        drawingType: BMAP_DRAWING_POLYGON,
        enableDrawingTool: false,
        enableCalculate: false,
        enableDragging: false,
        disableInertialDragging: true,
        drawingToolOptions: {
            anchor: BMAP_ANCHOR_TOP_LEFT,
            offset: new BMap.Size(5, 5),
            drawingTypes: [BMAP_DRAWING_POLYGON],
        },
        polygonOptions: styleOPtions,
    });
    mapObj.polygon.setDrawingMode("polygon");
    mapObj.polygon.addEventListener("overlaycomplete", callback);
    mapObj.polygon.open();
};
/** 行政区域
 * @param {String} val 行政区域名称
 */
BaiduMap.prototype.createRegions = function (val) {
    let name = val ? val : "重庆市";
    let bdary = new BMap.Boundary();
    bdary.get(name, (rs) => {
        this.map.clearOverlays();
        let count = rs.boundaries.length;
        if (count == 0) {
            console.log("未能获取当前行政区域");
            return;
        }
        let pointArr = [];
        for (let i = 0; i < count; i++) {
            let ply = new BMap.Polygon(rs.boundaries[i], {
                strokeWeight: 2,
                strokeColor: "#ff0000",
            });
            this.map.addOverlay(ply);
            pointArr = pointArr.concat(ply.getPath());
        }
        this.map.setViewport(pointArr);
    });
}
/** 清除指定marker
 * @param {Object} marker
 */
BaiduMap.prototype.removeMarker = function (marker) {
    this.map.removeOverlay(marker);
}
/** 
 * 清除所有覆盖物
 */
BaiduMap.prototype.clearOverlays = function () {
    if (this.openInfo) {
        this.openInfo.close();
        this.closeAllInfoBox();
    }
    if (this.drawTip) {
        this.drawTip = false;
    }
    this.map.clearOverlays();
}
/** 显示所有点的最佳地图层级 
 * @param {Array} points 所有点
*/
BaiduMap.prototype.setViewport = function (points) {
    /* var pointArr = [];
    for (var i = 0; i < points.length; i++) {
        pointArr.push(GPS.GPSToBaidu(points[i].lat, points[i].lat));
    }  */
    this.map.setViewport(points);
}
/** 
 * 测距
 */
BaiduMap.prototype.computeLength = function () {
    if (this.myDis) {
        this.myDis.close()
    }
    this.myDis = new BMapLib.DistanceTool(this.map, {
        lineColor: '#3270FF',
        secIcon:  new BMap.Icon(emptyIcon, new BMap.Size(12,12)),
        closeIcon: new BMap.Icon(closeIcon, new BMap.Size(12,12)),
    });
    //开始测量
    this.myDis.open();
}
/** 
 * 清空地图
 * @param {String} id 地图容器id
 */
BaiduMap.prototype.clearMap = function(id) {
    // 获取 map容器
    var oContainer = document.getElementById(id);
    // 获取 div 标签下的所有子节点
    var pObjs = oContainer.childNodes;
    for (var i = pObjs.length - 1; i >= 0; i--) {
        oContainer.removeChild(pObjs[i]);
    }
}
/** 设置地图类型
 * @param {String} str 地图类型
 */
BaiduMap.prototype.setMapType = function(str) {
    //BMAP_SATELLITE_MAP 无字卫星图
    let type = str == 'map' ? BMAP_NORMAL_MAP : BMAP_HYBRID_MAP;
    this.map.setMapType(type);
}
/** 
 * 地图全屏 
*/
BaiduMap.prototype.fullScreen = function(dom) {
    let element = document.getElementById(dom);
    let that = this;
    if(!that.currentStatus) {
        that.currentStatus = true;
        element.style.position = 'fixed';
        element.style.width = document.body.clientWidth+'px';
        element.style.height = document.body.clientHeight+'px';
        element.style.top = '0';
        element.style.left = '0';
        element.style.zIndex = 10;
        //监听esc事件
        document.addEventListener('keyup', function (e) {
            //ESC 点击事件
            if (e.keyCode == 27) {
                that.outFullScreen(element);
            }
        })
    } else {
        that.outFullScreen(element);
    }
}
BaiduMap.prototype.outFullScreen = function(element) {
    element.style.position = 'relative';
    element.style.width ='100%';
    element.style.height = '100%';
    this.currentStatus = false;
}

/** 
 * 放大地图
 */
BaiduMap.prototype.zoomOut = function() {
    var zoom = this.map.getZoom();
    zoom++;
    this.map.setZoom(zoom);
}
/** 
 * 缩小地图
 */
BaiduMap.prototype.zoomIn = function() {
    var zoom = this.map.getZoom();
    zoom--;
    this.map.setZoom(zoom);
}
/** 
 * 提示框随鼠标移动 
 */
BaiduMap.prototype._mouseAlt = function(opt) {
    var that = this;
    var box = document.getElementById(opt.targetDiv);
    if (!this.drawTip) {
        document.body.removeChild(document.getElementById("moualt"));
        return false;
    }
    box.onmouseover = function(){
        var moualt = document.createElement("div");
        document.body.appendChild(moualt);
        moualt.setAttribute("id","moualt");
    };
    box.onmousemove = function(ev){
        ev = ev || window.event;
        var mousePos = that._mousePosition(ev);
        if (!that.drawTip) {
            var dom =document.getElementById("moualt");
            if (dom) {
                document.body.removeChild(dom);
            }
            return false;
        }
        var a = document.getElementById("moualt");
        a.innerHTML = opt.info;
        a.style.cssText = "position:absolute;z-index:1000;padding:0 4px;background-color:#fff;border: 1px solid #3270FF;color: #262626;font-size:12px;border-radius:2px";
        a.style.left = mousePos.x + 10 + "px";
        a.style.top = mousePos.y + 10 + "px";
    };
    box.onmouseout = function(){
        var dom = document.getElementById("moualt");
        if (dom) {
            document.body.removeChild(dom);
        }
        
    }
}
/** 
 * 监听鼠标移动
 */
BaiduMap.prototype._mousePosition = function(ev) {
    if(ev.pageX || ev.pageY){
        return { x:ev.pageX,y:ev.pageY}
    }
    return{
        x:ev.clientX+document.body.scrollLeft-document.body.clientLeft,
        Y:ev.clientY+document.body.scrollTop-document.body.clientTop
    };
}
/** 显示多边形
 * @param {Object} points 点集合
 * @param {Object} styleObj 样式
 */
BaiduMap.prototype.showPolygon = function(points, styleObj) {
    var obj = {
        strokeColor: styleObj && styleObj.strokeColor ? styleObj.strokeColor :'#3270FF',
        strokeWeight: styleObj && styleObj.strokeWeight ? styleObj.strokeWeight : 3,
        fillColor: styleObj && styleObj.fillColor ? styleObj.fillColor : '#3270FF',
        fillOpacity: styleObj && styleObj.fillOpacity ? styleObj.fillOpacity : 0.1,
    }
    var pointList = [];
    for (var i = 0; i < points.length; i++) {
        pointList.push(this.createPoint(points[i]));
    }
    this.map.setViewport(pointList)
    var polygon = new BMap.Polygon(pointList, obj);
    if (styleObj && styleObj.editable) {
        polygon.enableEditing();
    }
    this.map.addOverlay(polygon);
    this.tempFlag = false;
    return polygon;
}
/** 显示圆形覆盖物
 * @param {Object} point 中心点
 * @param {String} radius 半径
 * @param {Object} styleObj 样式
 */
BaiduMap.prototype.showCircle = function(point, radius, styleObj) {
    var obj = {
        radius: radius,
        strokeColor: styleObj && styleObj.strokeColor ? styleObj.strokeColor :'#3270FF',
        strokeWeight: styleObj && styleObj.strokeWeight ? styleObj.strokeWeight : 3,
        fillColor: styleObj && styleObj.fillColor ? styleObj.fillColor : '#3270FF',
        fillOpacity: styleObj && styleObj.fillOpacity ? styleObj.fillOpacity : 0.1,
    }
    console.log(point);
    var circle = new BMap.Circle(this.createPoint(point), radius, obj);
    this.map.addOverlay(circle);
    if (styleObj && styleObj.editable) {
        // circle.enableEditing();
    }
    return circle;
}
/** 更改覆盖物后的回调函数
 * @param {Object} fence 覆盖物
 * @param {String} type 覆盖物类型
 */
BaiduMap.prototype.updateCallback = function(fence, callback, type) {
    this.drawTip = true;
    var that = this;
    if (type == 'polygon') {
        var tipObj = {
            info: '拖拽点更改围栏形状，释放完成',
            targetDiv: 'map'
        }
        this.drawTip = true;
        this._mouseAlt(tipObj);
        fence.addEventListener('mouseover', function() {
            that.tempFlag = true
        })
        fence.addEventListener('lineupdate', function(e) {
            if (!that.tempFlag) {
                return
            }
            var tempPoint = this.getPath();
            var pointList = [];
            tempPoint.forEach(function(item) {
                /* var obj ={
                    lng: item.lng,
                    lat: item.lat
                } */
                var obj = GPS.baiduToGPS(item.lat, item.lng)
                pointList.push(obj);
            })
            callback(pointList, type);
        });
    } else if (type = 'circle') {
        this.map.setViewport(fence.getPath());
        var east = fence.getBounds().getNorthEast();
        var overs = this.map.getOverlays();
        var over = overs[overs.length - 1];
        var label = '';
        var path = '';//半径线
        var oldCenter = fence.getCenter();//中心点
        console.log(oldCenter);
        var overPoint = {lat: oldCenter.lat, lng: east.lng};
        var icon = this._createIcon({
            url: centerIcon,
            size: [12,12],
            anchor: [0,-1],
        }); //中心点图标
        var marker = new BMap.Marker(oldCenter,icon);//自定义中心admarker
        this.map.addOverlay(marker);
        var oldRadius = fence.getRadius();//原始半径
        oldRadius = oldRadius < 1000 ? Number(oldRadius).toFixed(2) + 'm' : (oldRadius/1000).toFixed(2) + 'km';
        label = new BMap.Label(oldRadius, {
            offset: new BMap.Size(-14, -20)
        });
        label.setStyle({
            color: '#3270FF',
            fontSize : "16px",
            backgroundColor: 'transparent',
            border :"0",
            fontWeight: 500
        });
        marker.setLabel(label);
        var tipObj = {
            info: '按住鼠标左键拖拽点更改半径，松开鼠标确定围栏',
            targetDiv: 'map'
        }
        this.drawTip = true;
        this._mouseAlt(tipObj);
        var editIcon = this._createIcon({
            url: emptyIcon,
            size: [12,12],
            anchor: [0,-1],
        }); //可移动点图标
        var editMarker = new BMap.Marker(overPoint,editIcon,{enableDragging: true});//自定义中心admarker
        editMarker.enableDragging();
        this.map.addOverlay(editMarker);
        var flag = true;
        var tempPoint = '';//临时点，判断点是否在圆形覆盖物上
        path = new BMap.Polyline([oldCenter, overPoint], {strokeColor:"#3270FF", strokeWeight:2, strokeOpacity: 0.5});
        path.setStrokeStyle('dashed');
        this.map.addOverlay(path);
        var centerGPS = GPS.baiduToGPS(oldCenter.lat, oldCenter.lng);
        editMarker.addEventListener('dragging', function(e) {
            var point = e.point;
            var pointGPS = GPS.baiduToGPS(point.lat,point.lng);
            var tempDistance = GPS.distance(centerGPS.lat,centerGPS.lng,pointGPS.lat,pointGPS.lng);
            var radisText = tempDistance < 1000 ? Number(tempDistance).toFixed(2) + 'm' : (tempDistance / 1000).toFixed(2) + 'km';
            label.setContent(radisText);
            fence.setRadius(tempDistance);
            path.setPath([oldCenter, point]);
            // fence.setCenter()
        });
        editMarker.addEventListener('dragend', function(e) {
            var point = e.point;
            var pointGPS = GPS.baiduToGPS(point.lat,point.lng);
            var tempDistance = GPS.distance(centerGPS.lat,centerGPS.lng,pointGPS.lat,pointGPS.lng);
            var radisText = tempDistance < 1000 ? Number(tempDistance).toFixed(2) + 'm' : (tempDistance / 1000).toFixed(2) + 'km';
            label.setContent(radisText);
            fence.setRadius(tempDistance);
            path.setPath([oldCenter, point]);
            var obj = {
                // center: oldCenter,
                center: GPS.baiduToGPS(oldCenter.lat,oldCenter.lng),
                radius: tempDistance
            }
            callback(obj, type);
        });

        /* fence.addEventListener('lineupdate', function(e) {
            var arr = [oldCenter, e.target.getCenter()];
            console.log(arr);
            path.setPath(arr);
            var radius = e.target.getRadius();
            radius = radius < 1000 ? Number(radius).toFixed(2) + 'm' : (radius/1000).toFixed(2) + 'km';
            label.setContent(radius);
            that.map.addEventListener('mouseup', function (v) {
                tempPoint = v.point;
                if (BMapLib.GeoUtils.isPointInCircle(tempPoint, e.target)) {
                    var obj = {
                        center: e.target.getCenter(),
                        radius: e.target.getRadius()
                    }
                    callback(obj, type);
                    flag = false;
                    that.drawTip = false;
                }
            }); 
        }) */
    }
    
}
/** 百度地图添加搜索框
 * @param {String} id 搜索框id
 */
BaiduMap.prototype.addSearchInput = function(id,cb) {
    var that = this;
    var ac = new BMap.Autocomplete(    //建立一个自动完成的对象
        {'input' : id
        ,'location' : map
    });
    ac.addEventListener("onhighlight", function(e) {  
        var str = "";
        var _value = e.fromitem.value;
        var value = "";
        if (e.fromitem.index > -1) {
            value = _value.province +  _value.city +  _value.district +  _value.street +  _value.business;
        }
        str = "FromItem<br />index = " + e.fromitem.index + "<br />value = " + value;
        value = "";
        if (e.toitem.index > -1) {
            _value = e.toitem.value;
            value = _value.province +  _value.city +  _value.district +  _value.street +  _value.business;
        }
        str += "<br />ToItem<br />index = " + e.toitem.index + "<br />value = " + value;
        document.getElementById(id).innerHTML = str;
    });
    var myValue;
    ac.addEventListener("onconfirm", function(e) {    //鼠标点击下拉列表后的事件
    var _value = e.item.value;
        myValue = _value.province +  _value.city +  _value.district +  _value.street +  _value.business;
       document.getElementById(id).innerHTML ="onconfirm<br />index = " + e.item.index + "<br />myValue = " + myValue;
       console.log(myValue);
       that._setPlace(myValue,cb);
    });
}
/** 百度地图定位 */
BaiduMap.prototype._setPlace = function(value,cb) {
    var that = this;
    this.map.clearOverlays();
    function myFun(){
        var pp = local.getResults().getPoi(0).point;    //获取第一个智能搜索的结果
        that.map.centerAndZoom(pp, 14);
        that.marker = that.createMarker(pp,{icon:{url:positionMarker,size:[40,50],anchor:[0,0]}},null,false,null,null,null,true,true);
        cb({point:pp,search:'search',address:value});
    }
    var local = new BMap.LocalSearch(that.map, { //智能搜索
      onSearchComplete: myFun
    });
    local.search(value);
}
/** 百度地图设置显示范围 */
BaiduMap.prototype.setViewBounds = function(obj,obj1) {
    var b = new BMap.Bounds(new BMap.Point(obj.lng, obj.lat),new BMap.Point(obj1.lng, obj1.lat));
    console.log(b);
    try {    // js中尽然还有try catch方法，可以避免bug引起的错误
        BMapLib.AreaRestriction.setBounds(this.map, b); // 已map为中心，已b为范围的地图
    } catch (e) {
        // 捕获错误异常
        console.log(e);
    } 
     //限制区域
     /* var b = new BMap.Bounds(new BMap.Point(obj.lng, obj.lat), new BMap.Point(obj1.lng, obj1.lat));
     try {
            BMapLib.AreaRestriction.setBounds(map, b);
        } catch (e) {
            alert(e);
        } */
}
export default BaiduMap;