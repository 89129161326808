<template>
     <div class="map-bottom clearfix">
         <div class="zoom-container">
            <div class="map-zoom">
                <span class="zoom-large" @click="zoomOut"></span>
            </div>
            <div class="divider"></div>
            <div class="map-zoom">
                <span class="zoom-small" @click="zoomIn"></span>
            </div>
        </div>
        <div class="bottom-map-type">
            <div class="map-type-wrapper">
                <div class="map-type-card map-img" @click="toMap">
                    <span class="map-type-text">2D</span> 
                    <img src="../../assets/image/map/map.png" />
                    <div class="mask"><span>2D</span></div>
                </div>
                <div class="map-type-card map-moon-img" @click="toMoonMap">
                    <span class="map-type-text">卫星</span> 
                    <img src="../../assets/image/map/moon.png" />
                    <div class="mask"><span>卫星</span></div>
                </div>
            </div>
        </div>
     </div>
</template>
<script>
    export default {
        data() {
            return {
                sameMapType: 'map', //地图类型
            };
        },
        methods: {
            /** 
             * 放大地图
             */
            zoomOut() {
                this.$emit('zoomOut');
            },
            /** 
             * 缩小地图
             */
            zoomIn() {
                this.$emit('zoomIn');
            },
            /** 
             * 切换到默认地图
             */
            toMap() {
                this.sameMapType = 'map';
                this.$emit('changeMapType', this.sameMapType);
            },
            /** 
             * 切换到卫星地图
             */
            toMoonMap() {
                this.sameMapType = 'moon';
                this.$emit('changeMapType', this.sameMapType);
            }
        }
    }
</script>
<style scoped lang="scss">
    .map-bottom {
        position: absolute;
        right: 32px;
        bottom: 32px;
        &.clearfix {
            zoom: 1;
            &:after {
                display: block;
                content: '';
                width: 0;
                height: 0;
                visibility: hidden;
                clear: both;
            }
        }
         .zoom-container {
            float: right;
            width: 32px;
            height: 65px;
            z-index: 4;
            margin-bottom: 12px;
            box-shadow: 0px 9px 10px 8px rgba(0, 0, 0, 0.02), 0px 6px 8px 0px rgba(0, 0, 0, 0.04), 0px 3px 4px -4px rgba(0, 0, 0, 0.08);
            border-radius: 4px;
            background-color: #fff;
            .divider {
                width: 24px;
                height: 1px;
                margin: 0 auto;
                background: #F0F0F0;
            }
            .map-zoom {
                width: 32px;
                height: 32px;
                text-align: center;
                line-height: 32px;
                span {
                    display: inline-block;
                    width: 16px;
                    height: 16px;
                    cursor: pointer;
                }
                .zoom-large {
                    background: url('../../assets/image/map/enlarge.png');
                    &:hover {
                        background: url('../../assets/image/map/enlarge-select.png');
                    }
                }
                .zoom-small {
                    background: url('../../assets/image/map/smaller.png');
                    &:hover {
                        background: url('../../assets/image/map/smaller-select.png');
                    }
                }
            }
        }
        .bottom-map-type {
            position: relative;
            width: 105px;
            height: 65px;
            overflow: hidden;
            transition: all 1s;
            .map-type-wrapper {
                position: relative;
                width: 100%;
                height: 65px;
                .map-type-card {
                    // float: left;
                    position: absolute;
                    width: 97px;
                    height: 65px;
                    border: 1px solid #fff;
                    img {
                        width: 97px;
                        height: 65px;
                        border-radius: 4px;
                        cursor: pointer;
                    }
                    span {
                        position: absolute;
                        bottom: 5px;
                        right: 12px;
                        font-size: 14px;
                        color: #fff;
                    }
                    .map-type-text {
                        display: inline-block;
                    }
                     &:hover {
                        cursor: pointer;
                        .mask {
                            display: block;
                        }
                        .map-type-text {
                            display: none;
                        }
                    }
                }
                .mask {
                    display: none;
                    position: absolute;
                    top: 0;
                    width: 97px;
                    height: 65px;
                    border-radius: 4px;
                    border: 1px solid #3270FF;
                    background: rgba(50, 112, 255, .4);
                    z-index: 1;
                }
                .map-img {
                    left: 0;
                }
                .map-moon-img {
                    right: 0;
                    // transform: translateX(8px);
                } 
            }
            &:hover {
                width: 200px;
            }
        }
    }
</style>